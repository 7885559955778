import React, { useState } from 'react';
import styles from './MainPage.module.scss';

import bg from '../../res/bg.jpg'
import Button from '../../components/Button/Button';

import runners from '../../res/runners.jpg';
import route from '../../res/route.jpg';
import QuestionData from '../../model/QuestionData/QuestionData';
import Question from '../../components/Question/Question';
import Partners from '../Partners/Partners';
import Footer from '../Footer/Footer';
import ing_logo from '../../res/ing.png';

import g1 from '../../res/1.jpg';
import g2 from '../../res/2.jpg';
import g3 from '../../res/3.jpg';
import g4 from '../../res/4.jpg';

const MainPage: React.FC = () => {


    const q0 =
        <div className="text text--medium">
            W biegu mogą wziąć udział osoby pełnoletnie, które dokonały rejestracji i wniosły opłatę<br />
startową. Osoby niepełnoletnie mogą wziąć udział w biegu pod opieką osób dorosłych.<br />
Osoby niepełnoletnie mogą się zgłosić jedynie przez ich opiekunów/osoby dorosłe.    </div>


    const q1 =
        <div className="text text--medium">
            Tak, limit uczestników wynosi 2000.
        </div>

    const q2 =
        <div className="text text--medium">
            Organizator nie zapewnia urządzeń do pomiaru czasu.
            Uczestnik dokonuje pomiaru czasu za pomocą własnych urządzeń.
            W tym celu Uczestnik powinien posiadać urządzenie pozwalające na pomiar czasu, przebyty dystans, datownik i czas.
       Dla potwierdzenia pomiaru i przebytego dystansu Uczestnik zobowiązany jest do wypełnienia formularza danych zawodów na stronie <br /> <b><a href="https://www.wirtualny.biegnijwarszawo.pl">https://www.wirtualny.biegnijwarszawo.pl</a> maksymalnie do 17.10.2021r - niedziela (do godziny 09:00)</b>.  <br /><br />
       Po biegu każdy uczestników zobowiązany jest do przesłania pliku formatu TCX lub GPX z popularnych aplikacji typu Endomondo, Strava, Polar, Garmin, Suunto, itp poprzez formularz kontaktowy znajdujący na stronie w danych zawodach maksymalnie do 18 października do godz. 24:00.
       Można tamże załączyć zdjęcia w tym ze startu i mety swojego biegu.

       <br /><br />
 Pomiar uczestnika zostanie zarejestrowany w naszym systemie, jeśli przebyty dystans nie będzie krótszy od obowiązującego biegu. Czas z biegu należy podać na podstawie czasu zarejestrowanego w urządzeniu pomiarowym lub aplikacji na kilometrze kończącym ten bieg. Do klasyfikacji liczony będzie tylko czas z obowiązującego dystansu. Bieg zalicza się od pierwszego, pokonanego kilometra, czyli uczestnik musi przesłać czas z kilometrów lub 1-10.
    </div>

    const q3 =
        <div className="text text--medium">
            Każdy uczestnik biegu który ukończy bieg, może wydrukować certyfikat ukończenia biegu dostępny na stronie internetowej w pliku PDF

</div>
    const q4 =
        <div className="text text--medium">
            10 km w dowolnym miejscu, w terminie 19.09.2021 r od godz. 00:01 do 17.10.2021r . do godz. 23:59 </div>

    const q5 =
        <div className="text text--medium">
Każdy zarejestrowany zawodnik zobowiązany jest do wydrukowania numeru startowego który dostępny będzie w pliku PDF do samodzielnego wydruku.</div>

    const q6 =
        <div className="text text--medium">
Tylko raz można przebiec dystans. Akceptujemy tylko jeden plik do dystansu. <br/><br/> <b>Nie można poprawiać biegu.</b></div>

    const q7 =
        <div className="text text--medium">
            Nie przewidzieliśmy limitu na pokonanie całego dystansu. Ważne jest jednak, aby ukończyć bieg w terminie<br/> <b> 19.09.2021r od godz. 00:01 do 17.10.2021r . do godz. 23:59.</b> <br/><br/>
            Bieg może odbywać się na terenie Polski lub za granicą.
</div>

const q8 =
<div className="text text--medium">
Koszt pakietu startowego – 99 złotych <br/><br/>
W ramach opłaty uczestnik otrzymuje Koszulkę techniczna NIKE ( odbiór podany w oddzielnym komunikacie ) w terminie 02.10.2021 – 19.10.2021
</div>





    const [questionsState, setQuestionState] = useState<QuestionData[]>([
        { id: 0, question: "Kto może wziąć udział w biegu?", answer: q0, open: false },
        { id: 1, question: "Ile osób może wystartować w biegu?", answer: q1, open: false },
        { id: 2, question: "Jak mierzony jest czas?", answer: q2, open: false },
        { id: 3, question: "Jaka jest nagroda?", answer: q3, open: false },
        { id: 8, question: "Jaki jest koszt uczestnictwa?", answer: q8, open: false },
        { id: 4, question: "Jaki dystans należy pokonać?", answer: q4, open: false },
        { id: 5, question: "Czy otrzymam numer startowy?", answer: q5, open: false },
        { id: 6, question: "Czy mogę poprawić swój bieg?", answer: q6, open: false },
        { id: 7, question: "Ile mam czasu na przebiegnięcie 10 kilometrów?", answer: q7, open: false }
    ]);

    const handleQuestionOpen = (id: number) => {
        const newState = [...questionsState];
        const question = newState.find(question => question.id === id);
        if (question) {
            question.open = !question.open;
            setQuestionState(newState);
        }
    }


    return (
        <div className="container">
            <div className={`content__full u-margin-bottom-big ${styles.main_bg}`} style={{ backgroundImage: `url('${bg}')` }}>
                <div className={styles.main_bg__content}>
                    <h1 className={`heading heading__primary ${styles.main_bg__date}`}>Wirtualny</h1>
                    <h2 className={`heading heading__secondary text-color-white u-margin-bottom-huge u-text-center`}>02.10.2022</h2>
                    <Button className={styles.main_bg__button} >Zapisz się</Button>
                </div>
            </div>

            <section className={"content__center u-margin-bottom-big"} id="description">
                <h3 className={`heading heading__tretiary u-text-center u-margin-bottom-medium`}>Nasz Bieg</h3>
                <p className={`text`}>
                    Biegnij Warszawo to projekt wielkiego formatu, który powstał z inicjatywy grupy zaprzyjaźnionych ludzi, chcących ukazać ludzką aktywność w formie innej niż wyczynowa. Pomimo, że bieganie uchodziło za sport indywidualny, oni widzieli w nim wielki potencjał w realizacji nie tylko sportowych celów, ale głównie tych, które można nazwać aktywnymi celami społecznymi.
<br></br> <br />
To stało się inspiracją do realizacji sportowego widowiska w centralnej Polsce i stworzenia BIEGNIJ WARSZAWO Team – zespołu, który nie tylko zrzesza i jednoczy biegaczy, ale także pokazuje inny obraz biegania. Takiego, który oparty jest na własnym tempie, właściwej ścieżce i pełnej świadomości celu.

        <br /><br></br>
        Biegnij Warszawo to bieg, który od lat znajduje się w kalendarzu biegowym większości Polaków. Stał się biegową tradycją oraz obrazem, że miejska aktywność fizyczna wcale nie zanikła. Projekt, który pod anglojęzyczną nazwą RUN WARSAW miał pomóc w aktywizacji społeczeństwa, dzisiaj stał się nieodzownym elementem realizacji kalendarza biegowego wielu tysięcy biegaczy.
        </p>
            </section>
            <section className={`content__full container u-margin-bottom-big ${styles.route}`} id="route" style={{ backgroundImage: `url('${runners}')` }}>
                <div className={`content__center ${styles.route__content}`}>
                    <h3 className={`heading heading__tretiary u-text-center u-margin-bottom-medium text-color-white ${styles.route__content__heading}`}>Sponsor Główny</h3>
                    <div className={`${styles.route__content__main}`}>
                        <img src={ing_logo} className={`u-margin-bottom-medium ${styles.route__content__main__ing}`} />
                        <p className={`text`}>
                            </p>
                    </div>

                    <div className={styles.route__content__gallery}>
                        <div className={styles.route__content__gallery__item} style={{ backgroundImage: `url('${g1}')` }} ></div>
                        <div className={styles.route__content__gallery__item} style={{ backgroundImage: `url('${g2}')` }}></div>
                        <div className={styles.route__content__gallery__item} style={{ backgroundImage: `url('${g3}')` }}></div>
                        <div className={styles.route__content__gallery__item} style={{ backgroundImage: `url('${g4}')` }}></div>
                    </div>
                </div>
            </section>
            <section className={"content__center u-margin-bottom-big"} id="rules">
                <h3 className={`heading heading__tretiary u-text-center u-margin-bottom-medium`}>Zasady</h3>
                <div className={styles.rules__main}>
                    {
                        questionsState.map((question, index) =>
                            <Question
                                key={index}
                                id={question.id}
                                question={question.question}
                                answer={question.answer}
                                open={question.open}
                                onChangeOpen={handleQuestionOpen} />)
                    }
                </div>
            </section>
            <Partners />

        </div>
    )
}

export default MainPage;