import React, { useRef, useState } from 'react';
import styles from './Partner.module.scss';

type PartnerProps = {
    path: string,
    alt: string
}

const Partner: React.FC<PartnerProps> = (props) => {

    const imgRef = useRef<HTMLImageElement>(null);

    const [extraState, setExtraState] = useState('');

    const handleOnLoad = () => {
        const node = imgRef.current;

        if(node){
            console.log(node.naturalWidth + "x" + node.naturalHeight + " ALT: ", props.alt);

            if(node.naturalWidth - 20 >= node.naturalHeight) {
                setExtraState(styles.partner__img__horizontal);
            }else{
                setExtraState(styles.partner__img);
            }
        }
      //  console.log(imgRef.current?.)
    }

    return (
        <div className={styles.partner}>
            
            <img ref={imgRef} onLoad={handleOnLoad} className={`${extraState}`} src={props.path} alt={props.alt} />
        </div>
    )
}

export default Partner;