import React from 'react';
import styles from './Button.module.scss';

type ButtonProps = {
    className?: string
}

const Button: React.FC<ButtonProps> = (props) => {
    return (
        <button onClick={() => window.open("https://www.biegnijwarszawo.pl/startmeta/bing2021/", "_blank")} className={`${props.className} ${styles.button}`}>{props.children}</button>
    )
}

export default Button;