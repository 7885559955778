import React from 'react';
import styles from './Partners.module.scss';

/*
import partner1 from '../../res/zbrojna.png';
import partner2 from '../../res/aprocis.png';
import partner3 from '../../res/dhosting.png';
import partner4 from '../../res/miedzyzdroje.jpg';
import partner5 from '../../res/organizator_2.png';
import partner6 from '../../res/fundacja.png';
*/

import skm from '../../res/SKM.png';
import ing from '../../res/ing.png';

import bg from '../../res/grey.jpg';

import Partner from './Partner/Partner';

const Partners: React.FC = () => {

    const partners: {path: string, alt: string} [] = [
        {path: ing, alt: "ing"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2015/10/sporting_logo.jpg", alt: "sporting"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2021/06/ministerstwo.png", alt: "ministerstwo sportu"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2019/07/ZNAK_PROMOCYJNY_FC_PL-01.png", alt: "zakochaj sie w warszawie"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2015/10/magnesia.jpg", alt: "Magnesia"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2021/10/protiming.png", alt: "Protiming"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2015/10/melvit.jpg", alt: "melwit"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2015/10/Projekt-bez-tytułu-7.png", alt: "ForPro"},
        {path: skm, alt: "SKM"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2015/10/Projekt-bez-tytułu-5.png", alt: "MM Cars"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2015/10/druk_24h_logo.jpg", alt: "druk24.pl"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2015/10/logo-dhosting-1.jpg", alt: "dhosting.pl"},
        {path: "https://www.biegnijwarszawo.pl/wp-content/uploads/2021/07/Logo-Argotiab_kolor-1.jpg", alt: "argotiab"},
    ];

    return (
        <section id="sponsors" style={{backgroundImage: `url('${bg}')`}} className={`content__full container u-padding-top-huge u-padding-bottom-huge ${styles.partners}`}>
            <div className={`content__center ${styles.partners__main}`}>
                <h2 className="heading heading__tretiary heading--primary u-center u-margin-bottom-medium">Sponsorzy</h2>
                <div className={styles.partners__grid}>
                    {
                        partners.map((partner, index) => <Partner key={index} path={partner.path} alt={partner.alt} />)
                    }
                </div>
            </div>
        </section>
    )
}

export default Partners;