import React, { useState, useEffect, useLayoutEffect } from 'react';
import styles from './Header.module.scss';
import logo from '../../res/logo.png';
import ing_logo from '../../res/ing.png';

import { HashLink as Link } from 'react-router-hash-link';
function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const Header: React.FC = (props) => {
    const [width, height] = useWindowSize();

    const onContactClick = () => window.open("https://www.biegnijwarszawo.pl/kontakt/", "_blank");
    const onRegisterClick = () => window.open("https://www.biegnijwarszawo.pl/startmeta/bing2021/", "_blank");

    const [menuState, setMenuState] = useState(false);

    const onMenuClick = () => {
        if (width < 1200) setMenuState(state => !state);
    }

    useEffect(() => {

        if (width >= 1200) setMenuState(true);
        else setMenuState(false);
    }, [width])

    useEffect(() => {

        if (window.innerWidth >= 1200) setMenuState(true);

    }, [])

    return (
        <header className={styles.container}>
            <div className={styles.header}>
                <img src={logo} className={styles.header__logo} />
                <img src={ing_logo} className={styles.header__ing} />
                {
                    menuState &&
                    <nav className={styles.menu}>
                        <Link onClick={onMenuClick} to="/#description" className={`text text-color-primary ${styles.menu__item}`}>O Biegu</Link>
                        <Link onClick={onMenuClick} to="/#route" className={`text text-color-primary ${styles.menu__item}`}>Sponsor Główny</Link>
                        <Link onClick={onMenuClick} to="/#rules" className={`text text-color-primary ${styles.menu__item}`}>Zasady</Link>
                        <Link onClick={onMenuClick} to="/#sponsors" className={`text text-color-primary ${styles.menu__item}`}>Sponsorzy</Link>
                        <Link onClick={onMenuClick} to="/terms" className={`text text-color-primary ${styles.menu__item}`}>Regulamin</Link>
                        <span onClick={() => {
                            onMenuClick();
                            onContactClick();
                        }} className={`text text-color-primary ${styles.menu__item}`}>Kontakt</span>
                        <span className={styles.menu__line}></span>
                        <span onClick={() => {
                            onMenuClick();
                            onRegisterClick();
                        }} className={`text text-color-primary ${styles.menu__item}`}>Rejestracja</span>
                    </nav>
                }
                <div onClick={onMenuClick} className={`${styles.hamburger} ${menuState ? styles.active : ""}`}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </header>
    )
}

export default Header;