import React, { useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import Header from './containers/Header/Header';
import MainPage from './containers/MainPage/MainPage';
import Footer from './containers/Footer/Footer';
import { Switch, Route } from 'react-router-dom';

function App() {


  return (
    <div>

      <Header />
      <Switch>
        <Route path="/" exact>
          <React.Fragment>

            <MainPage />
            
          </React.Fragment>
        </Route>
        <Route path="/terms" exact>
          <React.Fragment>
            <div className="terms u-margin-bottom-huge">


              <div className="container">
                <div className="content__center">
                  <h5 className="heading heading__tretiary u-text-center u-margin-bottom-medium">Regulamin</h5>
                  <div className="text">
                    Administratorem Pana/Pani danych osobowych jest Bogusław Mamiński <br/>
                    „SPORTING", z wpisem do ewidencji działalności gospodarczej pod adresem:<br/>
                    Promenada Gwiazd 6, 72-500 Międzyzdroje (NIP: 8551010145; REGON:<br/>
                    810669294), oraz siedzibą w Warszawie przy ul. Myśliwieckiej 9 pokój 115<br/>
                    W sprawach związanych z przetwarzaniem danych osobowych można się<br/>
                    skontaktować pod adresem: biuro@biegnijwarszawo.pl. Więcej informacji<br/>
                    można uzyskać na stronie internetowej pod adresem:<br/>
                    <a href="https://www.biegnijwarszawo.pl/biegnij-warszawo/polityka-prywatnosci/">https://www.biegnijwarszawo.pl/biegnij-warszawo/polityka-prywatnosci/</a><br/>
                    <br/><br/>
                   <b> WIRTUALNY BIEGNIJ WARSZAWO</b><br/><br/>
                   <b>I. ORGANIZATOR</b> : Bogusławem Mamińskim – prowadzącym działalność gospodarczą pod nazwą Bogusław MAMIŃSKI ,,SPORTING’’ z siedzibą w Międzyzdrojach 72-500 , Promenada Gwiazd nr 6 ,wpisaną do Centralnej Ewidencji i informacji o Działalności Gospodarczej Rzeczypospolitej Polskiej  i posługujący się numerami NIP 8551010145 Regon 810669294 z Biurem : 00-459 Warszawa , ul Myśliwiecka 9 p 115 <br/><br/>
                   <b>II. CEL IMPREZY</b><br/>
1.	Upowszechnianie biegania jako najprostszej formy ruchu.<br/>
2.	Promocja aktywności fizycznej i zdrowego stylu życia wśród mieszkańców Polski.<br/>
3.	Promocja Warszawy jako miasta przyjaznego dla biegaczy.<br/><br/>
<b>III. WARUNKI UCZESTNICTWA</b><br/>
W biegu mogą wziąć udział osoby pełnoletnie, które dokonały rejestracji i wniosły opłatę<br/>
startową. Osoby niepełnoletnie mogą wziąć udział w biegu pod opieką osób dorosłych.<br/>
Osoby niepełnoletnie mogą się zgłosić jedynie przez ich opiekunów/osoby dorosłe.<br/>
Limit uczestników wynosi 2000.<br/><br/>
<b>IV. TERMIN i DYSTANS</b><br/>
Bieg ma charakter ,,wirtualny’’. Każdy uczestnik zobowiązany jest do pokonania w terminie
19.09.2022 r od godz. 00:01 do 17.10.2022r . do godz. 23:59 dystansu 10 km w dowolnym
miejscu. Bieg może odbywać się na terenie Polski lub za granicą. Każdy z Uczestników ma
zapewnioną swobodę wyboru trasy. Brak limitu czasu w zawodach. Bieg nie ma charakteru
masowego. Bieg Uczestnika ma charakter biegu indywidualnego. Każdy uczestnik
zobowiązany jest do przestrzegania aktualnych wytycznych rządu dotyczących PANDEMII
COVID-19<br/><br/>
<b>V. POMIAR CZASU</b><br/>
1. Organizator nie zapewnia urządzeń do pomiaru czasu. Uczestnik dokonuje pomiaru czasu
za pomocą własnych urządzeń. W tym celu Uczestnik powinien posiadać urządzenie
pozwalające na pomiar czasu, przebyty dystans, datownik i czas. Dla potwierdzenia pomiaru i
przebytego dystansu Uczestnik zobowiązany jest do wypełnienia formularza danych
zawodów na stronie <a href="https://www.wirtualny.biegnijwarszawo.pl">https://www.wirtualny.biegnijwarszawo.pl</a> maksymalnie do 17.10.2022 r -
niedziela (do godziny 09:00). Po biegu każdy uczestników zobowiązany jest do przesłania
pliku formatu TCX lub GPX z popularnych aplikacji typu Endomondo, Strava, Polar, Garmin,
Suunto, itp poprzez formularz kontaktowy znajdujący na stronie w danych zawodach
maksymalnie do 18 października do godz. 24:00. Można tamże załączyć zdjęcia w tym ze startu i
mety swojego biegu.<br/>
2. Pomiar uczestnika zostanie zarejestrowany w naszym systemie, jeśli przebyty dystans nie
będzie krótszy od obowiązującego biegu. Czas z biegu należy podać na podstawie czasu
zarejestrowanego w urządzeniu pomiarowym lub aplikacji na kilometrze kończącym ten
bieg. Do klasyfikacji liczony będzie tylko czas z obowiązującego dystansu. Bieg zalicza się od
pierwszego, pokonanego kilometra, czyli uczestnik musi przesłać czas z kilometrów lub 1-10.<br/>
3. Klasyfikacja tworzona jest w oparciu o dane przekazane zgodnie z podpunktem 1 w
punkcie “V”.
<br/><br/>
<b>VI. ZAPISY, PAKIETY i OPŁATY</b><br/>
1. Rejestracja zawodników oraz opłata wpisowego odbywa się za pomocą formularza
zgłoszeniowego dostępnego na stronie <a href="https://www.wirtualny.biegnijwarszawo.pl/">https://www.wirtualny.biegnijwarszawo.pl/</a> od niedzieli
02.08.2022 od godz. 00.01. do 02.10.2022 do godziny 24;00 <br/>
2. Koszt pakietu startowego –
99 złotych ( online podczas zapisów)w. <br/>
3. W przypadku rezygnacji z uczestnictwa opłata
startowa nie podlega zwrotowi.<br/>
 4. Tylko raz można przebiec dystans. Akceptujemy tylko
jeden plik do dystansu. Nie można poprawiać biegu. <br/>
5. Każdy zarejestrowany zawodnik
zobowiązany jest do wydrukowania numeru startowego który dostępny będzie w pliku PDF
do samodzielnego wydruku. <br/>
7. Po ukończeniu biegu uczestnicy proszeni są o umieszczenie
screenów biegu oraz zdjęć z biegu na swoje profile społecznościowe (FB lub Instagram) i nasz
profil www.facebok.com/<br/>
8. W ramach opłaty uczestnik otrzymuje Koszulkę techniczna NIKE ( odbiór podany w oddzielnym komunikacie )  w terminie 02.10.2022 – 19.10.2022
Po tym terminie pakiet przechodzi na własność  Bogusław MAMIŃSKI ,,SPORTING’’<br/><br/>
<b>VII. NAGRODY</b><br/>
 Każdy uczestnik biegu który ukończy bieg ,może wydrukować certyfikat ukończenia biegu dostępny na stronie internetowej w pliku PDF<br/><br/>
<b>VIII. ŚWIADCZENIA DLA UCZESTNIKÓW:</b><br/>
Organizator w związku z realizacją biegu zapewnia uczestnikowi: - numer startowy (do
pobrania) - wyniki ( na stronie organizatora) – (koszulka techniczna- certyfikat(do pobrania)
Organizator w związku z realizacją biegu nie zapewnia uczestnikowi: - opieki medycznej; -
toalet; - punktów odżywczych; - ubezpieczenia; - pomiaru czasu; ani innych usług
gwarantowanych w klasycznych biegach masowych.<br/>
<b>IX. POSTANOWIENIA KOŃCOWE</b><br/>
1. Uczestnicy biegu biorą w nim udział dobrowolnie oraz oświadczają że są świadomi
zagrożeń, ryzyka i obciążeń fizycznych jakie wiążą się z udziałem w zawodach i ponoszą z
tego tytułu pełną i wyłączną odpowiedzialność oraz że nie istnieją żadne przeciwwskazania
medyczne do wzięcia udziału w zawodach<br/>
2. Uczestnicy powinni być ubezpieczeni od następstw nieszczęśliwych wypadków.  <br/>
3.Uczestnik zobowiązuje się do: -przestrzegania regulaminu biegów (brak znajomości
regulaminu nie będzie uznawany jako usprawiedliwienie); -zachowania zasad
bezpieczeństwa w ruchu drogowym;; -kulturalnego zachowywania się; -samodzielnego
pokonania wyznaczonej trasy.<br/>
<br/>
4. Bieg będzie odbywać się przy nieograniczonym ruchu drogowym. Zawodnicy muszą
zachować szczególną ostrożność mając na uwadze możliwość wyjechania pojazdów z pól,
dróg leśnych czy pojedynczych zabudowań. Wszyscy zawodnicy na drogach gruntowych i
publicznych powinni poruszać się prawą stroną jezdni i bezwzględnie przestrzegać przepisów
Ruchu Drogowego. Organizator nie ponosi odpowiedzialności za ewentualne wypadki na
trasie wybranej przez zawodnika. Zawodnicy muszą szanować środowisko naturalne i
bezwzględnie nie mogą zanieczyszczać swojej trasy biegu. <br/>
5. Organizator zastrzega sobie
prawo do zmian w regulaminie bez podania przyczyny <br/>
6. Interpretacja regulaminu należy do
organizatora. W kwestiach nieuregulowanych niniejszym regulaminem, kwestie sporne
rozstrzyga Organizator.<br/>
 7. Organizator zobowiązuje się dostarczyć uczestnikom po
wykupieniu pakietu linku do wzoru okolicznościowego numeru startowego do pobrania. <br/>
8. Uczestnik wyraża zgodę na wykorzystywanie danych osobowych zawartych w formularzu
zgłoszeniowym ( zgodnie z ustawą z dnia 10 maja 2018r. O ochronie danych osobowych ( Dz.
U. 2018r. Poz. 1000 t.j.)oraz RODO – rozporządzenie Parlamentu Europejskiego i Rady ( UE)
2016*679 z dnia 27 kwietnia 2016r. W sprawie ochrony uchylenia dyrektywy 95/46/WE
przez Organizatora w zakresie i przez okres niezbędny dla organizacji, przeprowadzenia i
promocji zawodów, wyłonienia zwycięzców i odbioru nagród ( cel przetwarzania).
Przetwarzanie danych obejmuje także publikację wizerunku zawodnika, imienia i nazwiska
wraz z miejscowością, w której zamieszkuje, jego kategorię wiekową, rok urodzenia, płeć,
nazwę teamu/klubu, uzyskany podczas zawodów wynik- w każdy sposób, w jaki publikowana
lub rozpowszechniana będzie taka informacja dla potrzeb organizacji zawodów
POLITYKA PRYWATNOŚCI I OCHRONA DANYCH OSOBOWYCH Niniejszy dokument stanowi
integralną część regulaminu biegu Bogusław MAMIŃSKI ,,SPORTING’’
1 Bogusław MAMIŃSKI ,,SPORTING’’ organizator biegu przykłada szczególną wagę do ochrony
prywatności Uczestników. W tym celu z należytą starannością dobiera i stosuje środki
techniczne i organizacyjne zapewniające ochronę przetwarzanych danych odpowiednią do
zagrożeń oraz kategorii danych objętych ochroną. W szczególności zabezpiecza dane przed
ich udostępnieniem osobom nieupoważnionym, jak również przed ich przetwarzaniem z
naruszeniem obowiązujących przepisów prawa, zmianą, utratą, uszkodzeniem lub
zniszczeniem.<br/>
2. Administratorem zbioru danych osobowych jest Bogusław MAMIŃSKI ,,SPORTING’’<br/>
3. Podanie przez Usługobiorców danych osobowych jest dobrowolne. Podanie danych (imię,
nazwisko, adres korespondencji) jest niezbędne do świadczenia Usług.<br/>
4. Podstawą przetwarzania danych osobowych jest zgoda Usługobiorców, jak również
ustawowe upoważnienie do przetwarzania danych Usługodawców koniecznych do realizacji
Usług.<br/>
5. Usługobiorca ma prawo dostępu do treści swoich danych oraz ich poprawiania. W tym
celu należy skontaktować się z Bogusław MAMIŃSKI ,,SPORTING’’<br/>
6. Dane podawane przez Usługobiorców wykorzystywane są w celu realizacji Usług
świadczonych w związku z zawartą z Bogusław MAMIŃSKI ,,SPORTING’’, umową o świadczenie usług drogą elektroniczną. Ponadto, dane Usługobiorców wykorzystywane są w celach, dla których wyrazili oni odrębną zgodę.

                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>

        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
