import React from 'react';
import styles from './Question.module.scss';
import QuestionData from '../../model/QuestionData/QuestionData';


const Question: React.FC<QuestionData> = (props) => {

    const answerBox =
        <div className={`${styles.question__answer}  text text__primary`}>
            {props.answer}
        </div>

    return (
        <div className={`${styles.question} u-margin-bottom-small`}>
            <div className={styles.question__main} onClick={() => props.onChangeOpen &&  props.onChangeOpen(props.id)}>
                <div className="text text-color-white">
                    {props.question}
                </div>
            </div>
            {props.open && answerBox}
        </div>
    );
}


export default Question;