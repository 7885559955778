import React from 'react';
import styles from './Footer.module.scss';

import SEO_bg from '../../res/SEO_bg.jpg';
import footer_bg from '../../res/footer.jpg';

import logo from '../../res/logo.png';
import { HashLink } from 'react-router-hash-link';

const Footer: React.FC = () => {
    return (
        <footer className={`${styles.footer}`}>
            <div className={`container ${styles.SEO_footer}`} style={{ backgroundImage: `url('${SEO_bg}')` }}>
                <div className={`content__center ${styles.SEO_footer__content}`}>
                    <h4 className={`${styles.SEO_footer__font} heading--primary`}><span className={`${styles.SEO_footer__font} text-color-white`}>#biegnijwarszawo </span> 02.10.2021<span className={`${styles.SEO_footer__font} text-color-white`}> w Warszawie</span></h4>
                </div>
            </div>
            <div className={`container ${styles.main_footer}`} style={{ backgroundImage: `url('${footer_bg}')` }}>
                <div className={`content__center ${styles.main_footer__content}`}>
                    <div className={`${styles.main_footer__content__info} u-margin-bottom-big`}>
                        <img src={logo} className={styles.main_footer__content__info__logo} />
                        <span className="text text--medium text-color-white">
                            Zapraszamy na nasze profile. Bądź na bieżąco i nie przegap ważnych informacji związanych z biegiem!
                    </span>
                        <div className={styles.main_footer__content__info__social}>
                            <a href="https://www.facebook.com/biegnijwarszawo/?fref=ts"><i className="fab fa-facebook"></i></a>
                            <a href="https://www.facebook.com/biegnijwarszawo/?fref=ts">insta</a>
                            <a href="https://www.facebook.com/biegnijwarszawo/?fref=ts">ss</a>
                        </div>
                    </div>
                    <div className={styles.main_footer__content__short}>
                        <span className={`text text--medium text--big text-color-white ${styles.main_footer__content__short__heading}`}>Na skróty</span>
                        <ul className={styles.main_footer__content__short__list}>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}><HashLink to="/#description">O Biegu</HashLink></li>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}><HashLink to="/#route">Sponsor Główny</HashLink></li>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}><HashLink to="/#rules">Zasady</HashLink></li>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}><HashLink to="/#sponsors">Sponsorzy</HashLink></li>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}><HashLink to="/terms">Regulamin</HashLink></li>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}><a target="_blank" href="https://www.biegnijwarszawo.pl/kontakt/">Kontakt</a></li>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}><a target="_blank" href="https://www.biegnijwarszawo.pl/startmeta/bing2021/">Rejestrajca</a></li>
                        </ul>
                    </div>
                    <div className={styles.main_footer__content__short}>
                        <span className={`text text--medium text--big text-color-white ${styles.main_footer__content__short__heading}`}>Kontakt</span>
                        <ul className={styles.main_footer__content__short__list}>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}>Klub Biegacza Sporting

</li>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}>ul. Myśliwiecka 9 (Agrykola, p. 115)</li>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}>00-459 Warszawa

</li>
                            <li className={` text text--medium text-color-white ${styles.main_footer__content__short__list__item}`}>tel. (+48) 22 622 9108

</li>
                        </ul>
                    </div>

                    <div className={styles.main_footer__content__copyright}>
                        <div className={`${styles.main_footer__content__copyright__line} u-margin-bottom-small`}></div>
                        <div className={styles.main_footer__content__copyright__main}>
                            <span className={`text ${styles.main_footer__content__copyright__main__copyright}`}>Copyright © <a className="text text-color-white" href="https://www.biegnijwarszawo.pl/" style={{textDecoration: "none"}}>Biegnij Warszawo</a>. Wszelkie prawa zastrzeżone.</span>
                            <span className={`text ${styles.main_footer__content__copyright__main__copyright}`}>Created by&nbsp;&nbsp; <b>Jakub Kurek</b></span>

                        </div>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer;